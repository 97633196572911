@import 'scss/variables';

body {
  .AdvertiserModal {
    .Title {
      margin-bottom: 24px;
    }

    :global(.mu-p2) {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
